import { ActionButton, ButtonType, IStackTokens, MessageBarType, Stack } from '@fluentui/react';
import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import { userImportTemplateConstants } from '../../../../constants/userImportTemplateConstants';
import useTableFilter from '../../../hooks/batch-mode/useTableFilter';
import useUserDownload from '../../../hooks/batch-mode/useUserDownload';
import useUserImportTemplateValidation from '../../../hooks/batch-mode/useUserImportTemplateValidation';
import useUserUpload from '../../../hooks/batch-mode/useUserUpload';
import Loading from '../../Loading';
import Message from '../../Message';

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const EditCompanyUsers: React.FunctionComponent = () => {
	const userUpload = useUserUpload();
	const userDownload = useUserDownload();
	const userImportTemplateValidation = useUserImportTemplateValidation();
	const tableFilter = useTableFilter(userImportTemplateConstants.tableName);

	if (userUpload.isRequestInProgress) {
		return <Loading label={defineMessage({ id: 'uploadingUsers', defaultMessage: 'Uploading Users' })} progress={userUpload.progress} />;
	}

	if (userDownload.isRequestInProgress) {
		return <Loading label={defineMessage({ id: 'downloadingPendingUsers', defaultMessage: 'Downloading Pending Users' })} />;
	}

	if (!userImportTemplateValidation.isSpreadsheetValid) {
		return (
			<Message messageBarType={MessageBarType.error} 
				message={defineMessage({
					id: 'spreadsheetIsNotValid',
					defaultMessage: 'The spreadsheet is not in the format expected.'
				})}
			/>
		)
	}

	const handleClick = async(callback) => {
		userDownload.resetState();
		userUpload.resetState();
		
		if (await tableFilter.checkIsFilterApplied()) {
			return;
		}

		callback();
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='managePendingUsers' defaultMessage='Manage Pending Users' />
			</h2>
			{userUpload.uploadHasOccurred && (
				<Message messageBarType={MessageBarType.info}
					message={defineMessage({
						id: 'usersHaveBeenUploaded',
						defaultMessage: 'Your upload has been processed. Review the Result column for more details.'
					})}
				/>
			)}
			{userDownload.pendingUsersFromApiCount === 0 && (
				<Message messageBarType={MessageBarType.info}
					message={defineMessage({
						id: 'noPendingUsers',
						defaultMessage: 'There are no pending users to download.'
					})}
				/>
			)}
			{userDownload.pendingUsersFromApiCount > 0 && (
				<Message messageBarType={MessageBarType.success}
					message={defineMessage({
						id: 'pendingUsersDownloaded',
						defaultMessage: 'Pending users downloaded successfully.'
					})}
				/>
			)}
			{userUpload.usersUploadedCount === 0 && (
				<Message messageBarType={MessageBarType.error}
					message={defineMessage({
						id: 'noUsersToUpload',
						defaultMessage: 'There are no users to upload.'
					})}
				/>
			)}
			{tableFilter.isFilterApplied && (
				<Message messageBarType={MessageBarType.error} 
					message={defineMessage({
						id: 'cannotUploadUsersfilterIsApplied',
						defaultMessage: 'Cannot upload or download users while a filter is applied. Clear any filters on the table and retry.'
					})}
				/>
			)}
			<Stack grow verticalFill tokens={stackTokens}>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Download' }}
						onClick={() => handleClick(userDownload.downloadUsers)}
						data-testid='downloadButton'
					>
						<FormattedMessage id='downloadUsers' defaultMessage='Download Users' />
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<ActionButton
						className='ms-welcome__action'
						buttonType={ButtonType.hero}
						iconProps={{ iconName: 'Upload' }}
						onClick={() => handleClick(userUpload.uploadUsers)}
						data-testid='uploadButton'
					>
						<FormattedMessage id='uploadUsers' defaultMessage='Upload Users' />
					</ActionButton>
				</Stack.Item>
			</Stack>
		</>
	);
};

export default EditCompanyUsers;
