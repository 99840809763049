import { useContext, useState } from 'react';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { SessionContext } from '../../context/SessionContext';
import { LocalStorageTokens } from '../../enums/LocalStorageTokens';

const useApi = () : {
    post: (endpoint:string, requestBody: unknown, customHeaders?: HeadersInit) => Promise<Response>;
    get: (endpoint:string) => Promise<Response>;
    patch: (endpoint:string, requestBody: unknown) => Promise<Response>;
    patchContents: (endpoint:string, requestBody: unknown) => Promise<Response>;
    isRequestInProgress: boolean;
} => {
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const { server } = useContext(CustomPropertiesContext);
    const { setIsSessionExpired } = useContext(SessionContext);

    // Define the post function that sends a POST request to the API endpoint.
    const post = (endpoint: string, requestBody: unknown, customHeaders?: HeadersInit) : Promise<Response> => {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(customHeaders || {})
            },
            body: JSON.stringify(requestBody)
        };

        return callApi(endpoint, requestOptions);
    };

    // Define the get function that sends a GET request to the API endpoint.
    const get = (endpoint: string) : Promise<Response> => {
        const requestOptions: RequestInit = {
            method: 'GET'
        };

        return callApi(endpoint, requestOptions);
    };

    const patch = (endpoint: string, requestBody: unknown) : Promise<Response> => {
        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        return callApi(endpoint, requestOptions);
    }

    const patchContents = (endpoint: string, blob: Blob) : Promise<Response> => {
        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/offset+octet-stream',
                'Upload-Offset': '0',
                'Content-Length': blob.size.toString()
            },
            body: blob
        };

        return callApi(endpoint, requestOptions);
    };

    // Define the callApi function that sends an HTTP request to the API endpoint.
    const callApi = async (endpoint: string, requestOptions: RequestInit) : Promise<Response> => {
        setIsRequestInProgress(true);

        requestOptions.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem(LocalStorageTokens.AccessToken),
            ...(requestOptions.headers || {})
        };

        let response: Response;
        try {
            // Send the HTTP request to the API endpoint with the specified method, headers, and request body.
            response = await fetch(server + endpoint, requestOptions);

            if (response.status === 401) {
                setIsSessionExpired(true);
            }
        } catch (error) {
            console.error(`Failed to ${requestOptions.method} data. Error details: ${error.message}`);
        }

        setIsRequestInProgress(false);

        return response;
    };

    // Return an object containing the put, get, and isRequestInProgress functions.
    return { post, get, patch, patchContents, isRequestInProgress};
}

// Export the useApi hook as the default export of the module.
export default useApi;