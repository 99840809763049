import { useState } from "react";
import { userImportTemplateConstants } from "../../../constants/userImportTemplateConstants";
import { PostUserDTO } from "../../types/PostUserDTO";
import useApi from "../useApi";

const useUserUpload = () : {
    isRequestInProgress: boolean;
	uploadHasOccurred: boolean;
	progress: number;
	usersUploadedCount?: number;
	uploadUsers: () => void;
	resetState: () => void;
} => {
    const api = useApi();
	const [uploadHasOccurred, setUploadHasOccurred] = useState<boolean>(false);
	const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false);
	const [progress, setProgress] = useState<number>(0);
	const [usersUploadedCount, setUsersUploadedCount] = useState<number>(null);

	// Method to reset necessary state values after initial run
	const resetState = () => {
		setUploadHasOccurred(false);
		setUsersUploadedCount(null);
	}

	const outputResult = (results, table: Excel.Table) => {
		const resultColumn = table.columns.getItemOrNullObject(userImportTemplateConstants.resultColumnHeader);

		if (resultColumn) {
			resultColumn.delete();
		}

		table.columns.add(null /* add column to end of table */, [
			[userImportTemplateConstants.resultColumnHeader], 
			...results
		]);

		table.getDataBodyRange().format.autofitColumns();
	};

	const uploadUsers = () => {
		Excel.run(async context => {
			setIsRequestInProgress(true);
			setProgress(0);

			// Get the currently active worksheet
			const sheet = context.workbook.worksheets.getActiveWorksheet();

			const usersTable = sheet.tables.getItemOrNullObject(userImportTemplateConstants.tableName);
            const bodyRange = usersTable.getDataBodyRange().load("values");
			
			// Execute the batch operation
			await context.sync();

			// Access the values and row indices of the used range
			const cellValues = bodyRange.values;
			const totalUsersToUpload = cellValues.length;

			// Check if every cell in the table is empty as it's possible to have a table with many rows but no values
			// and a table must have a minimum of one row.
			if (bodyRange.values.every(row => row.every(cellValue => cellValue === null || cellValue === ''))) {
				setUsersUploadedCount(0);
				setIsRequestInProgress(false);
				return;
			}

			setUsersUploadedCount(totalUsersToUpload);
			const apiResults = [];

			// Process each row in the table
			for (let i = 0; i < totalUsersToUpload; i++) {
				const row = cellValues[i];

				// Create a user object for the current row
				const user : PostUserDTO = {
					userName: row[0],
					firstName: row[1],
					lastName: row[2],
					workPhone: row[3],
					mobilePhone: row[4],
					referenceNumber: row[5],
					email: row[6],
					tier2Name: row[7],
					userRole: row[8],
					includedInRelatedProjects: true
				};

				const response = await api.post(`users/company-users`, user);

				// Check the response status
				if (response.ok) {
					apiResults.push(["Success"]);
				} else {
					const responseBody = await response.json();
					const errorMessage = `Error: ${responseBody.message.replace("Tier2Name", "Business Unit").replace("Tier2", "Business Unit")}`;
					apiResults.push([errorMessage]);
				}
				
				setProgress(((i + 1) / totalUsersToUpload));
			}

			outputResult(apiResults, usersTable);
			setUploadHasOccurred(true);
			setIsRequestInProgress(false);
		}).catch(excelError => {
			// Handle any errors
			setIsRequestInProgress(false);
			console.error(excelError);
		});
	};

    return { 
        isRequestInProgress, 
		uploadHasOccurred,
		progress,
		usersUploadedCount,
		uploadUsers,
		resetState
    }
}

export default useUserUpload;