import { useEffect, useState } from "react";

const useTableFilter = (tableName: string) : {
    isFilterApplied: boolean;
    checkIsFilterApplied: () => Promise<boolean>;    
} => {
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    const checkIsFilterApplied = async () : Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            Excel.run(async (context) => {
                try {
                    const sheet = context.workbook.worksheets.getActiveWorksheet();
                    const table = sheet.tables.getItemOrNullObject(tableName).load("autoFilter");

                    await context.sync();

                    if (table.isNullObject) {
                        if (isMounted) {
                            setIsFilterApplied(false);
                        }
                        resolve(false);
                    }

                    // Determine if there is a filter applied
                    const filterApplied = table.autoFilter.isDataFiltered;

                    if (isMounted) {
                        setIsFilterApplied(filterApplied);
                    }
                    
                    resolve(filterApplied);
                } catch (excelError) {
                    console.error(excelError);
                    reject(excelError);
                }
            });
        });
    };

    return {
        isFilterApplied,
        checkIsFilterApplied
    };
}

export default useTableFilter;