import { useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { Communication } from '../types/CommunicationDataContract';
import { i18nMessage } from '../types/i18nMessage';
import useApi from './useApi';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCommunication(communicationId: number) {
	const api = useApi();
	const [isLoadingCommunicationDetails, setIsLoadingCommunicationDetails] = useState<boolean>(true);
	const [communicationErrorToken, setCommunicationErrorToken] = useState<i18nMessage>();
	const [communication, setCommunication] = useState<Communication>();

	useEffect(() => {
		const getCommunication = async () => {
			let response: Response;

			try {
				response = await api.get('communications/' + communicationId);

				if (response.ok) {
					setCommunication(await response.json());
				} else {
					setCommunicationErrorToken(determineErrorToken(endpointRefs.getCommunication, response.status));
				}
			} catch (error) {
				setCommunicationErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
			}
			setIsLoadingCommunicationDetails(false);
		};

		getCommunication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [communicationId]);

	return { isLoadingCommunicationDetails, communication, communicationErrorToken };
}
