import { IconButton, IStackStyles, IStackTokens, Panel, PanelType, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import UserProfile from './UserProfile';
import { SessionContext } from '../../../context/SessionContext';

export interface IHeaderProps {
	page: string;
	goBack: () => void;
	isValidFile: boolean;
}

const stackStyles: IStackStyles = {
	root: {
		overflow: 'hidden'
	}
};

// Tokens definition
const stackTokens: IStackTokens = {
	childrenGap: 5,
	padding: 5
};

// eslint-disable-next-line react/prop-types
const Header: React.FunctionComponent<IHeaderProps> = ({ goBack, page, isValidFile }: IHeaderProps) => {
	const intl = useIntl();
	const backText = intl.formatMessage({
		id: 'home',
		defaultMessage: 'Home'
	});
	const userProfileText = intl.formatMessage({
		id: 'userProfile',
		defaultMessage: 'User Profile'
	});
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
	const [showUserProfile, setShowUserProfile] = useState(false);
	const { activeUser } = useContext(SessionContext);

	useEffect(() => {
		if (activeUser && isValidFile) {
			setShowUserProfile(true);
		}
	}, [isValidFile, activeUser]);

	return (
		<>
			<Stack horizontal styles={stackStyles} tokens={stackTokens}>
				<Stack.Item grow>
					{(!page.includes('HOME')) && (
						<IconButton
							iconProps={{ iconName: 'Home' }}
							title={backText}
							ariaLabel={backText}
							onClick={goBack}
						/>
					)}
				</Stack.Item>
				{showUserProfile && (
					<Stack.Item styles={{ root: { marginRight: 10 } }}>
						<IconButton
							iconProps={{ iconName: 'UserOptional' }}
							title={userProfileText}
							ariaLabel={userProfileText}
							onClick={openPanel}
						/>
						<Panel
							isOpen={isOpen}
							onDismiss={dismissPanel}
							type={PanelType.smallFluid}
							closeButtonAriaLabel={intl.formatMessage({
								id: 'close',
								defaultMessage: 'Close'
							})}
							headerText={userProfileText}
						>
							<UserProfile />
						</Panel>
					</Stack.Item>
				)}
			</Stack>
			{(page.includes('HOME')) && (
				<section className='ms-welcome__header ms-u-fadeIn500'>
					<img
						src='assets/AVEVA_Logo_color_RGB.png'
						alt={intl.formatMessage({ id: 'aveva', defaultMessage: 'AVEVA™' })}
						title={intl.formatMessage({ id: 'aveva', defaultMessage: 'AVEVA™' })}
					/>
					<h1 className='header ms-fontSize-xxl ms-fontWeight-light ms-fontColor-neutralPrimary'>
						<FormattedMessage
							id='contractRiskManagementFileAssistant'
							defaultMessage='Contract Risk Management File Assistant'
						/>
					</h1>
				</section>
			)}
		</>
	);
};

export default Header;
