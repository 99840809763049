import { ActionButton, IStackTokens, MessageBarType, Stack, TextField } from '@fluentui/react';
import { defineMessage } from '@formatjs/intl';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileParentType } from '../../../enums/FileParentType';
import { FilePostDTO } from '../../types/FilePostDTO';
import Message from '../Message';
import useFile from '../../hooks/useFile';
import useFileUpload from '../../hooks/useFileUpload';
import Loading from '../Loading';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const SaveAsNewDocument: React.FunctionComponent<props> = ({ setPage }: props) => {
	const intl = useIntl();
	const [fileName, setFileName] = useState<string>();
	const [isFileNameValid, setIsFileNameValid] = useState(false);
	const { file, fileErrorToken, isLoadingFileDetails } = useFile();
	const fileUpload = useFileUpload();
	const filePostDto: FilePostDTO = {
		fileName: fileName
	};
	
	let suffix = '';

	// Determine the file type based on the open office app
	// istanbul ignore if
	if (Office.context.host == Office.HostType.Word) {
		filePostDto.fileExtension = 'docx';
		suffix = 'docx';
	} else {
		filePostDto.fileExtension = 'xlsx';
		suffix = 'xlsx';
	}

	const regex = new RegExp('[:*?<>""/|\\\\]');
	const invalidFileNames = [
		'AUX',
		'COM1',
		'COM2',
		'COM3',
		'COM4',
		'COM5',
		'COM6',
		'COM7',
		'COM8',
		'COM9',
		'CON',
		'LPT1',
		'LPT2',
		'LPT3',
		'LPT4',
		'LPT5',
		'LPT6',
		'LPT7',
		'LPT8',
		'LPT9',
		'NUL',
		'PRN'
	];

	const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFileName(e.target.value);
	};

	const handleFileNameValidation = (value: string): string => {
		if (value.length === 0) {
			setIsFileNameValid(false);
			return intl.formatMessage({ id: 'fileNameEmpty', defaultMessage: 'The file name cannot be empty.' });
		}

		if (value.length > 145) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameTooLong',
				defaultMessage: 'The file name cannot be longer than 145 characters.'
			});
		}

		if (value.match(regex)) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameInvalidChar',
				defaultMessage:
					'This file name contains invalid characters. Invalid characters include * : ? < > " / | \\'
			});
		}

		if (invalidFileNames.includes(value.toUpperCase())) {
			setIsFileNameValid(false);
			return intl.formatMessage({
				id: 'fileNameWindowsReserved',
				defaultMessage: 'This file name is reserved by Windows. Please enter a different name.'
			});
		}

		setIsFileNameValid(true);
		return '';
	};

	const uploadAndKeepReservation = async () => {
		await fileUpload.uploadFile(filePostDto, getFileUploadEndpoint(), true, defineMessage({
			id: 'fileUploadSaveAsNewDocumentSuccess',
			defaultMessage: 'The file was uploaded as a new file. The original file is still reserved for editing.'
		}));
	};

	const uploadAndReleaseReservation = async () => {
		await fileUpload.uploadFile(filePostDto, getFileUploadEndpoint(), false, defineMessage({
			id: 'fileUploadReleaseSaveAsNewDocumentSuccess',
			defaultMessage:
				'The file was uploaded as a new file and the reservation on the original file has been cleared.'
		}));
	};

	const getFileUploadEndpoint = (): string => {
		// Figure out the correct endpoint to call. Only communications or internal notes are supported
		if (file.parentType === FileParentType.Communication) {
			return 'communications/' + file.parentProConId + '/files';
		} else {
			return 'internal-notes/' + file.parentProConId + '/files';
		}
	};

	if (fileErrorToken) {
		return <Message messageBarType={MessageBarType.error} message={fileErrorToken} />;
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='saveAsNewDocument' defaultMessage='Save as a New File' />
			</h2>

			{isLoadingFileDetails && 
				<Loading />
			}

			{fileUpload.errorToken && (
				<Message messageBarType={MessageBarType.error} message={fileUpload.errorToken} />
			)}

			{fileUpload.isRequestInProgress && (
				<Loading label={defineMessage({ id: 'uploading', defaultMessage: 'Uploading' })} />
			)}

			{fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && (
				<>
					<Message messageBarType={MessageBarType.success} message={fileUpload.successToken} />
					<br />
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setPage('HOME');
						}}
						iconProps={{ iconName: 'Accept' }}
				>
					<FormattedMessage id='ok' defaultMessage='OK' />
				</ActionButton></>
			)}

			{!fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && !isLoadingFileDetails &&  (<>
				<TextField
					onGetErrorMessage={handleFileNameValidation}
					label={intl.formatMessage({ id: 'fileName', defaultMessage: 'File Name' })}
					onChange={handleFileNameChange}
					suffix={'.' + suffix}
					validateOnLoad={false}
					className='file-name'
					autoFocus
				/>
				<Stack grow verticalFill tokens={stackTokens}>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							onClick={uploadAndKeepReservation}
							disabled={!isFileNameValid}
							iconProps={{ iconName: 'SaveAs' }}
							data-testid='upload-and-keep-button'
						>
							<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							onClick={uploadAndReleaseReservation}
							disabled={!isFileNameValid}
							iconProps={{ iconName: 'Save' }}
							data-testid='upload-and-clear-button'
						>
							<FormattedMessage
								id='uploadAndClearReservation'
								defaultMessage='Upload and Clear Reservation'
							/>
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							iconProps={{ iconName: 'Cancel' }}
							onClick={() => {
								setPage('HOME');
							}}
						>
							<FormattedMessage id='cancel' defaultMessage='Cancel' />
						</ActionButton>
					</Stack.Item>
				</Stack>
			</>)}
		</>
	);
};

export default SaveAsNewDocument;
