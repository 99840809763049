import React, { useCallback, useEffect, useState } from 'react';
import Header from './pages/Header';
import Loading from './Loading';
// images references in the manifest
import '../../../assets/favicon_16x16.png';
import '../../../assets/favicon_24x24.png';
import '../../../assets/favicon_32x32.png';
import '../../../assets/favicon.png';
import '../../../assets/AVEVA_Logo_color_RGB.png';
import { useOffice } from './Office';
import { IntlProvider, defineMessage } from 'react-intl';
import defaultMessages from '../../../lang-dist/en.json';
import useCustomDocumentProperty from '../hooks/useCustomDocumentProperty';
import { CustomDocumentProperties, CustomDocumentPropertyError } from '../../enums/CustomDocumentProperties';
import Home from './pages/Home';
import Login from './pages/Login';
import FileDetails from './pages/FileDetails';
import VersionedFile from './pages/VersionedFile';
import NonVersionedFile from './pages/NonVersionedFile';
import Message from './Message';
import { Utils } from '../../utils/Utils';
import { MessageBarType } from '@fluentui/react';
import SaveAsNewVersion from './pages/SaveAsNewVersion';
import SaveAsNewDocument from './pages/SaveAsNewDocument';
import ReleaseReservation from './pages/ReleaseReservation';
import ReplaceExistingDocument from './pages/ReplaceExistingDocument';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { i18nMessage } from '../types/i18nMessage';
import Footer from './pages/Footer';
import BatchModeHome from './pages/batch-mode/BatchModeHome';
import { SessionContext } from '../../context/SessionContext';
import { CRMUser } from '../types/CRMUser';

const loadLocaleData = (locale: string) => {
	switch (locale) {
		case 'ru-RU':
			return import('../../../lang-dist/ru.json');
		default:
			return import('../../../lang-dist/en.json');
	}
};

const PAGES = {
	FILE_DETAILS: FileDetails,
	HOME: Home,
	LOGIN: Login,
	NON_VERSIONED_FILE: NonVersionedFile,
	VERSIONED_FILE: VersionedFile,
	SAVE_NEW_VERSION: SaveAsNewVersion,
	SAVE_NEW_DOCUMENT: SaveAsNewDocument,
	RELEASE_RESERVATION: ReleaseReservation,
	REPLACE_DOCUMENT: ReplaceExistingDocument,
	BATCH_MODE_HOME: BatchModeHome
};

const App: React.FunctionComponent = () => {
	const office = useOffice();
	const locale: string = Office.context.displayLanguage;
	const [localeMessages, setLocaleMessages] = useState(defaultMessages);
	const [isReadingSetting, setIsReadingSetting] = useState(true);
	const [server] = useCustomDocumentProperty<string>(CustomDocumentProperties.ServerName);
	const [fileId] = useCustomDocumentProperty<number>(CustomDocumentProperties.FileId);
	const [ConnectSolutionId] = useCustomDocumentProperty<string>(CustomDocumentProperties.ConnectSolutionId);
	const [timestamp] = useCustomDocumentProperty<string>(CustomDocumentProperties.Timestamp);
	const [importType] = useCustomDocumentProperty<string>(CustomDocumentProperties.ImportType);
	const [page, setPage] = useState<string>('HOME');
	const [isValidFile, setIsValidFile] = useState(true);
	const [errorToken, setErrorToken] = useState<i18nMessage>();
	const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
	const [activeUser, setActiveUser] = useState<CRMUser>(null);

	const determineHomePage = useCallback(() => {
		if (importType && importType !== CustomDocumentPropertyError) {
			setPage('BATCH_MODE_HOME');
		} else {
			setPage('HOME');
		}
	}, [importType]);

	const goBack = useCallback(() => {
		determineHomePage();
	}, [determineHomePage]);

	const handleError = (message: i18nMessage) => {
		setErrorToken(message);
		setIsValidFile(false);
		setIsReadingSetting(false);
	};

	const determineMarkup = () => {
		if (!office.isReady || isReadingSetting) {
			return <Loading displayExcelCellEditWarning={office.host === Office.HostType.Excel} />;
		}

		if (errorToken) {
			return <Message messageBarType={MessageBarType.error} message={errorToken} />;
		}

		return <CurrentPage setPage={setPage} />;
	};

	const CurrentPage = PAGES[page];

	useEffect(() => {
		loadLocaleData(locale).then((messages) => {
			setLocaleMessages(messages);
		});
		
	}, [determineHomePage, locale]);

	// Run on load
	useEffect(() => {
		if (!office.isReady) {
			return;
		}
		
		determineHomePage();

		// Only office running on Windows is supported
		// Office online gave issues with custom doc properties and no other platforms have been tested
		if (Office.context.platform !== Office.PlatformType.PC) {
			handleError(
				defineMessage({
					id: 'platformNotSupported',
					defaultMessage:
						'This add-in is currently only supported for Microsoft Office desktop applications on Windows.'
				})
			);

			return;
		}

		if (!Utils.areRequiredApiSetsAvailable()) {
			handleError(
				defineMessage({
					id: 'apiSetsNotAvailable',
					defaultMessage: "This add-in isn't compatible with your version of Microsoft Office or Windows."
				})
			);

			return;
		}

		// This is an import doc and we don't need to worry about File ID
		if (importType !== CustomDocumentPropertyError) {
			setIsReadingSetting(false);
			return;
		}

		// The required custom OOXML properties were not found in the docs metadata
		if (server === CustomDocumentPropertyError || timestamp === CustomDocumentPropertyError) {
			handleError(
				defineMessage({
					id: 'fileNotSupported',
					defaultMessage: 'This file is not supported because the required details were not found.'
				})
			);

			return;
		}

		// Check that the required OOXML properties have all been retrieved successfully
		if (server && fileId && timestamp) {
			setIsReadingSetting(false);
		}
	}, [office, server, fileId, timestamp, importType, determineHomePage]);

	return (
		<SessionContext.Provider value= {{ isSessionExpired, setIsSessionExpired, activeUser, setActiveUser }}>
			<CustomPropertiesContext.Provider
				value={{ 
					server: server, 
					fileId: fileId,  
					connectSolutionId: ConnectSolutionId, 
					importType: importType
				}}
			>
				<IntlProvider locale={locale} messages={localeMessages}>
					<Header goBack={goBack} page={page} isValidFile={isValidFile} />
					<div className='ms-welcome__main'>{determineMarkup()}</div>
					<Footer isValidFile={isValidFile} />
				</IntlProvider>
			</CustomPropertiesContext.Provider>
		</SessionContext.Provider>
	);
};

export default App;
