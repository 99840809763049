import { createContext } from 'react';

type CustomPropertiesProvider = {
	server: string;
	fileId: number;
	connectSolutionId: string;
	importType?: string;
};

const customProps: CustomPropertiesProvider = { server: '', fileId: 0, connectSolutionId: '', importType: null };
export const CustomPropertiesContext = createContext(customProps);
