import { ActionButton, IStackTokens, MessageBarType, Stack } from '@fluentui/react';
import React, { useContext } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import { FilePostDTO } from '../../types/FilePostDTO';
import Message from '../Message';
import useCustomDocumentProperty from '../../hooks/useCustomDocumentProperty';
import { CustomDocumentProperties } from '../../../enums/CustomDocumentProperties';
import useFileUpload from '../../hooks/useFileUpload';
import Loading from '../Loading';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const ReplaceExistingDocument: React.FunctionComponent<props> = ({ setPage }: props) => {
	const [timestamp] = useCustomDocumentProperty<string>(CustomDocumentProperties.Timestamp);
	const { fileId } = useContext(CustomPropertiesContext);
	const fileUpload = useFileUpload();
	const fileUploadEndpoint = 'files/' + fileId;

	const getFilePostDto = (): FilePostDTO => {
		return {
			version: timestamp
		};
	}

	const uploadAndKeepReservation = async () => {
		await fileUpload.uploadFile(getFilePostDto(), fileUploadEndpoint, true, defineMessage({
			id: 'fileUploadReplaceExistingDocumentSuccess',
			defaultMessage:
				'The file was uploaded and the original has been replaced. This file is still reserved for editing.'
		}));
	};

	const uploadAndReleaseReservation = async () => {
		await fileUpload.uploadFile(getFilePostDto(), fileUploadEndpoint, false, defineMessage({
			id: 'fileUploadReleaseReplaceExistingDocumentSuccess',
			defaultMessage:
				'The file was uploaded and the original has been replaced. The reservation has been cleared.'
		}));
	};

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='replaceExistingDocument' defaultMessage='Replace Existing File' />
			</h2>

			{fileUpload.errorToken && (
				<Message messageBarType={MessageBarType.error} message={fileUpload.errorToken} />
			)}

			{fileUpload.isRequestInProgress && (
				<Loading label={defineMessage({ id: 'uploading', defaultMessage: 'Uploading' })} />
			)}

			{fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && (
				<>
					<Message messageBarType={MessageBarType.success} message={fileUpload.successToken} />
					<br />
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setPage('HOME');
						}}
						iconProps={{ iconName: 'Accept' }}
				>
					<FormattedMessage id='ok' defaultMessage='OK' />
				</ActionButton></>
			)}

			{!fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && (  
				<Stack grow verticalFill tokens={stackTokens}>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							onClick={uploadAndKeepReservation}
							iconProps={{ iconName: 'SaveAs' }}
							disabled={!fileUpload.isUploadRequestSafeToSend}
						>
							<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							onClick={uploadAndReleaseReservation}
							iconProps={{ iconName: 'Save' }}
							disabled={!fileUpload.isUploadRequestSafeToSend}
						>
							<FormattedMessage
								id='uploadAndClearReservation'
								defaultMessage='Upload and Clear Reservation'
							/>
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							iconProps={{ iconName: 'Cancel' }}
							onClick={() => {
								setPage('HOME');
							}}
						>
							<FormattedMessage id='cancel' defaultMessage='Cancel' />
						</ActionButton>
					</Stack.Item>
				</Stack>
			)}
		</>
	);
};

export default ReplaceExistingDocument;
