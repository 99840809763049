import { useContext, useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { CRMUser } from '../types/CRMUser';
import { i18nMessage } from '../types/i18nMessage';
import useApi from './useApi';
import { SessionContext } from '../../context/SessionContext';
import useSession from './useSession';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useUser() {
	const api = useApi();
	const { server } = useContext(CustomPropertiesContext);
	const { activeUser, setActiveUser } = useContext(SessionContext);
	const [isLoadingUserDetails, setIsLoadingUserDetails] = useState<boolean>(true);
	const [userErrorToken, setUserErrorToken] = useState<i18nMessage>();
	const [user, setUser] = useState<CRMUser>();
	const session = useSession();

	const getUser = async () => {
		setIsLoadingUserDetails(true);

		try {
			const response: Response = await api.get('executing-user/user-details');

			if (response.ok) {
				const userDetails: CRMUser = await response.json();
				setUser(userDetails);
				setActiveUser(userDetails);
			} else {
				setUserErrorToken(determineErrorToken(endpointRefs.getUser, response.status));
				session.terminateSession();
			}
		} catch (error) {
			setUserErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
			session.terminateSession();
		}

		setIsLoadingUserDetails(false);
	};

	useEffect(() => {
		if (!server) {
			return;
		}

		if (activeUser) {
			setIsLoadingUserDetails(false);
			return;
		}

		getUser();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [server, activeUser, setActiveUser]);

	return { isLoadingUserDetails, user, userErrorToken };
}
