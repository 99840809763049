import { MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { reviewState } from '../../../enums/CRMStateProperties';
import { Utils } from '../../../utils/Utils';
import Loading from '../Loading';
import Message from '../Message';
import useCommunicationReview from '../../hooks/useCommunicationReview';

type props = {
	fileParentId: number;
};

const ReviewDetails: React.FunctionComponent<props> = ({ fileParentId }: props) => {
	const { isLoadingCommunicationReviewDetails, communicationReview, communicationReviewErrorToken } =
		useCommunicationReview(fileParentId);
	const [timezoneOffset, setTimezoneOffset] = useState<string>();

	useEffect(() => {
		if (isLoadingCommunicationReviewDetails || !communicationReview) {
			return;
		}

		setTimezoneOffset('(UTC' + Utils.getTimezoneOffset(communicationReview.dueDate) + ')');
	}, [isLoadingCommunicationReviewDetails, communicationReview]);

	if (communicationReviewErrorToken) {
		return <Message messageBarType={MessageBarType.error} message={communicationReviewErrorToken} />;
	}

	if (isLoadingCommunicationReviewDetails) {
		return <Loading />;
	}

	return (
		<>
			<h2 className='ms-fontWeight-light file-details-h2'>
				<FormattedMessage id='communicationReviewDetails' defaultMessage='Communication Review Details' />
			</h2>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='title' defaultMessage='Title' />
			</label>
			<span data-testid='review-title'>{communicationReview.title}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='communicationReference' defaultMessage='Communication Reference' />
			</label>
			<span data-testid='communication-reference'>{communicationReview.communicationProConReference}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='communicationTitle' defaultMessage='Communication Title' />
			</label>
			<span data-testid='communication-title'>{communicationReview.communicationTitle}</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='assignedOnDate' defaultMessage='Assigned On Date' />
			</label>
			<span data-testid='assigned-on-date'>
				{new Date(communicationReview.assignedOnDate).toLocaleDateString()}
			</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='dueDate' defaultMessage='Due Date' />
			</label>
			<span data-testid='due-date'>
				{new Date(communicationReview.dueDate).toLocaleDateString() + ', '}
				{new Date(communicationReview.dueDate).toLocaleTimeString() + ' '}
				{timezoneOffset}
			</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='state' defaultMessage='State' />
			</label>
			<span data-testid='review-state'>
				<FormattedMessage id={reviewState[communicationReview.state].id} />
			</span>
			<label className='ms-fontWeight-bold file-details-label'>
				<FormattedMessage id='finalDecision' defaultMessage='Final Decision' />
			</label>
			<span data-testid='final-decision'>
				{communicationReview.decisionLabel != null ? (
					communicationReview.decisionLabel
				) : (
					<FormattedMessage id='notApplicable' defaultMessage='Not applicable' />
				)}
			</span>
		</>
	);
};

export default ReviewDetails;
