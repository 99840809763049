import { useEffect, useState } from "react";
import { userImportTemplateConstants } from "../../../constants/userImportTemplateConstants";

const useUserImportTemplateValidation = () : {
    isSpreadsheetValid: boolean;
    validateSpreadsheetHeaders: () => void;
} => {
    const [isSpreadsheetValid, setIsSpreadsheetValid] = useState<boolean>(false);

    useEffect(() => { 
        // Run on load
        registerTableChangedEvent();
        validateSpreadsheetHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Register the onChanged function to the usersTable
    // Each time the table data changes, the validation will run
    const registerTableChangedEvent = () => {
        Excel.run(async (context) => {
			const sheet = context.workbook.worksheets.getActiveWorksheet();
            const usersTable = sheet.tables.getItemOrNullObject(userImportTemplateConstants.tableName);

            await context.sync();

            if (usersTable.isNullObject) {
                return;
            }

            usersTable.onChanged.add(validateSpreadsheetHeaders);
        });
    }

    const validateSpreadsheetHeaders = async () => {
        Excel.run(async (context) => {
			// Get the currently active worksheet
			const sheet = context.workbook.worksheets.getActiveWorksheet();

            const usersTable = sheet.tables.getItemOrNullObject(userImportTemplateConstants.tableName).load("autoFilter");
            const headerRange = usersTable.getHeaderRowRange().load("values");

            await context.sync();

            if (usersTable.isNullObject) {
                setIsSpreadsheetValid(false);
                return;
            }

            const expectedHeaders = [
                'Username',
                'First Name',
                'Last Name',
                'Work Phone',
                'Mobile Phone',
                'Reference Number',
                'Email',
                'Business Unit',
                'User Role'
            ];
            
            const actualHeaders = headerRange.values;

            // The spreadsheet has been loaded after an upload has been initiated
            // and therefore the Result column is present at the end of the table
            if (actualHeaders[0].includes(userImportTemplateConstants.resultColumnHeader)) {
                expectedHeaders.push(userImportTemplateConstants.resultColumnHeader);
            }

            if (actualHeaders[0].length !== expectedHeaders.length) {
                setIsSpreadsheetValid(false);
                return;
            }
            
            for (let i = 0; i < actualHeaders[0].length; i++) {
                if (actualHeaders[0][i] !== expectedHeaders[i]) {
                    setIsSpreadsheetValid(false);
                    return;
                }
            }
            
            setIsSpreadsheetValid(true);
        });
    };

    return { 
        isSpreadsheetValid,
        validateSpreadsheetHeaders
    }
}

export default useUserImportTemplateValidation;