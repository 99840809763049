class Utils {
	// Updates the specified custom property OOXML to the value provided
	static updateCustomProperty(customProp: string, value: any): void {
		if (Office.context.host == Office.HostType.Word) {
			Word.run(async (context) => {
				context.document.properties.customProperties.add(customProp, value);
				await context.sync();
			});
		} else if (Office.context.host == Office.HostType.Excel) {
			Excel.run({ delayForCellEdit: true }, async (context) => {
				context.workbook.properties.custom.add(customProp, value);
				await context.sync();
			});
		}
	}

	// Get the timezone offset - lifted from directive-proconLocalDateTimeDisplay.js in CRM
	static getTimezoneOffset(date: Date): string {
		const tempDate = new Date(date).getTimezoneOffset();
		let minute: number = (tempDate % 60) * -1;
		const hour = (tempDate * -1 - minute) / 60;
		minute = minute < 0 ? minute * -1 : minute;

		if (hour + minute === 0) {
			return '';
		}

		const minuteString = minute < 10 ? '0' + minute : minute;
		let hourString: string;
		if (hour < 0) {
			hourString = (hour + minute > 0 ? +hour : hour).toString().replace('-', '- ');
		} else {
			hourString = (hour + minute > 0 ? '+ ' + hour : hour).toString();
		}

		return hourString + ':' + minuteString;
	}

	// Check that the required API sets the app needs are available in the runtime
	static areRequiredApiSetsAvailable(): boolean {
		if (!Office.context.requirements.isSetSupported('DialogApi', '1.2')) {
			return false;
		}

		if (
			Office.context.host === Office.HostType.Word &&
			!Office.context.requirements.isSetSupported('WordApi', '1.3')
		) {
			return false;
		} else if (
			Office.context.host === Office.HostType.Excel &&
			!Office.context.requirements.isSetSupported('ExcelApi', '1.7')
		) {
			return false;
		}

		return true;
	}
}

export { Utils };
