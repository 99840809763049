import {
	ActionButton,
	DefaultButton,
	Dialog,
	DialogType,
	DialogFooter,
	PrimaryButton,
	Persona,
	IPersonaSharedProps,
	PersonaSize
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { SessionContext } from '../../../context/SessionContext';
import useSession from '../../hooks/useSession';

const modalPropsStyles = { main: { maxWidth: 450 } };

// eslint-disable-next-line react/prop-types
const UserProfile: React.FunctionComponent = () => {
	const [persona, setPersona] = useState<IPersonaSharedProps>();
	const { activeUser } = useContext(SessionContext);
	const intl = useIntl();
	const { logOut } = useSession();

	const dialogContentProps = {
		type: DialogType.normal,
		title: intl.formatMessage({ id: 'logOut', defaultMessage: 'Log Out' }),
		subText: intl.formatMessage({ id: 'confirmLogOut', defaultMessage: 'Are you sure you want to log out?' })
	};

	const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
	const modalProps = React.useMemo(
		() => ({
			isBlocking: true,
			styles: modalPropsStyles
		}),
		[]
	);

	useEffect(() => {
		if (!activeUser) {
			return;
		}

		const persona: IPersonaSharedProps = {
			imageInitials: activeUser.firstName.charAt(0) + activeUser.lastName.charAt(0),
			text: activeUser.firstName + ' ' + activeUser.lastName,
			secondaryText: activeUser.email
		};

		setPersona(persona);
	}, [activeUser]);

	return (
		<div className='ms-welcome__main'>
			<br />
			<Persona {...persona} size={PersonaSize.size40} />
			
			<ActionButton
				className='ms-welcome__action login-button'
				iconProps={{ iconName: 'UserRemove' }}
				onClick={toggleHideDialog}
			>
				<FormattedMessage id='logOut' defaultMessage='Log Out' />
			</ActionButton>
			
			<Dialog
				hidden={hideDialog}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
			>
				<DialogFooter>
					<PrimaryButton onClick={logOut}>
						<FormattedMessage id='ok' defaultMessage='OK' />
					</PrimaryButton>
					<DefaultButton onClick={toggleHideDialog}>
						<FormattedMessage id='cancel' defaultMessage='Cancel' />
					</DefaultButton>
				</DialogFooter>
			</Dialog>
		</div>
	);
};

export default UserProfile;
