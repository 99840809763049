import { ActionButton, IStackTokens, MessageBarType, Stack, TextField } from '@fluentui/react';
import { defineMessage } from '@formatjs/intl';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomPropertiesContext } from '../../../context/CustomPropertiesContext';
import { FilePostDTO } from '../../types/FilePostDTO';
import Message from '../Message';
import useFileUpload from '../../hooks/useFileUpload';
import Loading from '../Loading';
import useFile from '../../hooks/useFile';
import useCustomDocumentProperty from '../../hooks/useCustomDocumentProperty';
import { CustomDocumentProperties } from '../../../enums/CustomDocumentProperties';

type props = {
	setPage: (page: string) => void;
};

const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const SaveAsNewVersion: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { fileId } = useContext(CustomPropertiesContext);
	const [timestamp] = useCustomDocumentProperty<string>(CustomDocumentProperties.Timestamp);
	const [versionNote, setVersionNote] = useState<string>();
	const [isFileVersionNoteValid, setIsFileVersionNoteValid] = useState(false);
	const intl = useIntl();
	const { file } = useFile();
	const fileUpload = useFileUpload();
	const fileUploadEndpoint: string = 'files/' + fileId + '/versions';

	const handleVersionNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVersionNote(e.target.value);
	};

	const handleVersionNoteValidation = (value: string): string => {
		if (value.length > 255) {
			setIsFileVersionNoteValid(false);

			return intl.formatMessage({
				id: 'fileReasonForChangeTooLong',
				defaultMessage: 'The reason for change cannot be longer than 255 characters.'
			});
		}

		setIsFileVersionNoteValid(true);
		return '';
	};

	const getFilePostDto = (): FilePostDTO => {
		return {
			fileVersionNote: versionNote,
			version: timestamp,
			fileName: file.fileName,
			isConfidential: file.isConfidential,
			isVisibleInContractorPortal: file.isVisibleInContractorPortal
		};
	};

	const uploadAndKeepReservation = async () => {
		await fileUpload.uploadFile(getFilePostDto(), fileUploadEndpoint, true, defineMessage({
			id: 'fileUploadSaveAsNewVersionSuccess',
			defaultMessage: 'The file was uploaded as a new version. This file is still reserved for editing.'
		}));
	}

	const uploadAndClearReservation = async () => {
		await fileUpload.uploadFile(getFilePostDto(), fileUploadEndpoint, false, defineMessage({
			id: 'fileUploadReleaseSaveAsNewVersionSuccess',
			defaultMessage: 'The file was uploaded as a new version and the reservation has been cleared.'
		}));
	}

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='saveAsNewVersion' defaultMessage='Replace File with New Version' />
			</h2>

			{fileUpload.errorToken && (
				<Message messageBarType={MessageBarType.error} message={fileUpload.errorToken} />
			)}

			{fileUpload.isRequestInProgress && (
				<Loading label={defineMessage({ id: 'uploading', defaultMessage: 'Uploading' })} />
			)}

			{fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && (
				<>
					<Message messageBarType={MessageBarType.success} message={fileUpload.successToken} />
					<br />
					<ActionButton
						className='ms-welcome__action'
						onClick={() => {
							setPage('HOME');
						}}
						iconProps={{ iconName: 'Accept' }}
				>
					<FormattedMessage id='ok' defaultMessage='OK' />
				</ActionButton></>
			)}

			{!fileUpload.isUploadRequestSafeToSend && (
				<Message
					messageBarType={MessageBarType.error}
					message={defineMessage({
						id: 'cannotUploadWhileInEditMode',
						defaultMessage: 'The file cannot be uploaded. Please ensure you are not in cell-editing mode.'
					})}
				/>
			)}

			{!fileUpload.isUploadSuccessful && !fileUpload.isRequestInProgress && (<>
				<TextField
					onGetErrorMessage={handleVersionNoteValidation}
					label={intl.formatMessage({ id: 'reasonForChange', defaultMessage: 'Reason for Change' })}
					multiline
					rows={3}
					onChange={handleVersionNoteChange}
					className='versioning-note'
					autoFocus
				/>
				<Stack grow verticalFill tokens={stackTokens}>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							disabled={!isFileVersionNoteValid}
							onClick={uploadAndKeepReservation}
							iconProps={{ iconName: 'SaveAs' }}
							data-testid='upload-and-keep-button'
						>
							<FormattedMessage id='uploadAndKeepReservation' defaultMessage='Upload and Keep Reservation' />
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							disabled={!isFileVersionNoteValid}
							onClick={uploadAndClearReservation}
							iconProps={{ iconName: 'Save' }}
							data-testid='upload-and-clear-button'
						>
							<FormattedMessage
								id='uploadAndClearReservation'
								defaultMessage='Upload and Clear Reservation'
							/>
						</ActionButton>
					</Stack.Item>
					<Stack.Item>
						<ActionButton
							className='ms-welcome__action'
							iconProps={{ iconName: 'Cancel' }}
							onClick={() => {
								setPage('HOME');
							}}
						>
							<FormattedMessage id='cancel' defaultMessage='Cancel' />
						</ActionButton>
					</Stack.Item>
				</Stack>
			</>)}
		</>
	);
};

export default SaveAsNewVersion;
