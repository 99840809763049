import { useCallback, useEffect, useState } from 'react';
import { CustomDocumentPropertyError } from '../../enums/CustomDocumentProperties';

export default function useCustomDocumentProperty<TValue>(name: string): [TValue, () => void] {
	const [value, setValue] = useState<TValue>();

	const loadProperty = useCallback(() => {
		Office.onReady(() => {
			if (Office.context.host == Office.HostType.Word) {
				Word.run(async (context) => {
					const property = context.document.properties.customProperties
						.getItemOrNullObject(name)
						.load('value');
					await context.sync();

					const value = property.isNullObject ? logError(name) : property.value;
					setValue(value);
				});
			} else if (Office.context.host == Office.HostType.Excel) {
				Excel.run({ delayForCellEdit: true }, async (context) => {
					const property = context.workbook.properties.custom.getItemOrNullObject(name).load('value');
					await context.sync();

					const value = property.isNullObject ? logError(name) : property.value;
					setValue(value);
				});
			}
		});
	}, [name]);

	const logError = (property: string) => {
		console.warn('Property not found: ' + property);
		return CustomDocumentPropertyError;
	};

	useEffect(() => {
		loadProperty();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	return [value, loadProperty];
}
