import { MessageBarType } from '@fluentui/react';
import React, { useContext, useEffect } from 'react';
import { defineMessage } from 'react-intl';
import { CustomPropertiesContext } from '../../../../context/CustomPropertiesContext';
import { SessionContext } from '../../../../context/SessionContext';
import { ImportType } from '../../../../enums/ImportType';
import useUser from '../../../hooks/useUser';
import Loading from '../../Loading';
import Message from '../../Message';
import Login from '../Login';
import EditCompanyUsers from './EditCompanyUsers';

const BatchModeHome: React.FunctionComponent = () => {
	const { userErrorToken, isLoadingUserDetails } = useUser();
	const { importType } = useContext(CustomPropertiesContext);
	const { isSessionExpired } = useContext(SessionContext);
	
	useEffect(() => {
		// The API calls are still resolving or there is an error
		if (isLoadingUserDetails || userErrorToken) {
			return;
		}
	}, [isLoadingUserDetails, userErrorToken]);

	// Still reading custom properties or awaiting response from the server
	if (isLoadingUserDetails) {
		return <Loading />;
	}

	if (userErrorToken || isSessionExpired) {
		return <Login />;
	}

    if (importType === ImportType.UserSetup) {
        return <EditCompanyUsers />
    }
    
    return <>
        <Message messageBarType={MessageBarType.error} message={defineMessage({
            id: 'fileNotSupported',
            defaultMessage: 'This file is not supported because the required details were not found.'
        })} />;
    </>
}

export default BatchModeHome;