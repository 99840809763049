import { MessageBarType } from '@fluentui/react';
import React, { useContext } from 'react';
import Loading from '../Loading';
import Login from './Login';
import Message from '../Message';
import VersionedFile from './VersionedFile';
import NonVersionedFile from './NonVersionedFile';
import useFile from '../../hooks/useFile';
import { SessionContext } from '../../../context/SessionContext';
import useUser from '../../hooks/useUser';

type props = {
	setPage: (page: string) => void;
};

const Home: React.FunctionComponent<props> = ({ setPage }: props) => {
	const { userErrorToken, isLoadingUserDetails } = useUser();
	const { file, fileErrorToken, isLoadingFileDetails } = useFile();
	const { isSessionExpired } = useContext(SessionContext);

	if (isSessionExpired || userErrorToken) {
		return <Login />;
	}

	if (fileErrorToken) {
		return (<Message
					messageBarType={MessageBarType.error}
					message={fileErrorToken}
				/>
		);
	}

	// Still reading custom properties or awaiting response from the server
	if (isLoadingFileDetails || isLoadingUserDetails) {
		return <Loading />;
	}

	if (file.isVersionTrackingEnabled) {
		return <VersionedFile setPage={setPage} />;
	} else {
		return <NonVersionedFile setPage={setPage} />;
	}
};

export default Home;
