import React from 'react';
import { Icon, MessageBar, MessageBarType } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { i18nMessage } from '../types/i18nMessage';
import Login from './pages/Login';

export interface IMessageProps {
	messageBarType: MessageBarType;
	message: i18nMessage;
}

const Message: React.FunctionComponent<IMessageProps> = ({ messageBarType, message }: IMessageProps) => {
	if (message.id === 'fileNotReservedByYou') {
		return (
			<div>
				<Icon iconName='lock' data-testid='lockIcon'></Icon>&nbsp;
				<FormattedMessage
					id='fileNotReservedByYou'
					defaultMessage='This file is not reserved by you for editing.'
				/>
			</div>
		);
	}

	if (message.id === 'unauthorised') {
		return <Login hideHeader={true} />;
	}

	return (
		<div data-testid='messageWrapper'>
			<MessageBar messageBarType={messageBarType} data-testid={message.id}>
				<FormattedMessage id={message.id} defaultMessage={message.defaultMessage} />
			</MessageBar>
		</div>
	);
};

export default Message;
