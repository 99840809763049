import { useContext } from "react";
import { SessionContext } from "../../context/SessionContext";
import { CustomPropertiesContext } from "../../context/CustomPropertiesContext";
import { LocalStorageTokens } from "../../enums/LocalStorageTokens";

export default function useSession() {
	const { setIsSessionExpired, setActiveUser } = useContext(SessionContext);
	const { server, connectSolutionId } = useContext(CustomPropertiesContext);
	
	const terminateSession = () => {
		setIsSessionExpired(true);
		setActiveUser(null);
	};

	// Logs the user out by deleting the local storage tokens and redirecting to the logout page
	const logOut = () => {		
		localStorage.removeItem(LocalStorageTokens.AccessToken);
		localStorage.removeItem(LocalStorageTokens.AccessTokenExpiry);

		Office.context.ui.displayDialogAsync(window.location.origin + '/logout.html?serverName=' + server + '&connectSolutionId=' + connectSolutionId, {
			height: 70,
			width: 40,
			promptBeforeOpen: false
		});

		window.location.reload();
	}

	return { terminateSession, logOut };
}
