import { useContext, useEffect, useState } from 'react';
import { endpointRefs } from '../../constants/errorTokens';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { CRMFile } from '../types/CRMFile';
import { i18nMessage } from '../types/i18nMessage';
import useApi from './useApi';
import { SessionContext } from '../../context/SessionContext';
import { defineMessage } from 'react-intl';
import useCustomDocumentProperty from './useCustomDocumentProperty';
import { CustomDocumentProperties } from '../../enums/CustomDocumentProperties';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useFile() {
	const api = useApi();
	const { fileId } = useContext(CustomPropertiesContext);
	const [timestamp] = useCustomDocumentProperty<string>(CustomDocumentProperties.Timestamp);
	const { activeUser } = useContext(SessionContext);
	const [isLoadingFileDetails, setIsLoadingFileDetails] = useState<boolean>(true);
	const [fileErrorToken, setFileErrorToken] = useState<i18nMessage>();
	const [file, setFile] = useState<CRMFile>(null);

	const getFile = async () => {
		if (!activeUser || !timestamp) { 
			return;
		}

		let fileData: CRMFile;

		try {
			const response = await api.get('files/' + fileId);
	
			if (response.ok) {
				fileData = await response.json();
				setFile(fileData);
				validateFile(fileData)
			} else {
				setFileErrorToken(determineErrorToken(endpointRefs.getFile, response.status));
			}
		} catch (error) {
			setFileErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
		}
		
		setIsLoadingFileDetails(false);
	};

	const validateFile = (file: CRMFile) => {
		if (file.reservedByProConId !== activeUser.buyerProConId) {
			setFileErrorToken(defineMessage({
				id: 'fileNotReservedByYou',
				defaultMessage: 'This file is not reserved by you for editing.'
			}));
		}
	
		if (file.version !== timestamp) {
			setFileErrorToken(defineMessage({
				id: 'fileOutOfSync',
				defaultMessage:
					'This is not the latest version of the file. Close this file and download the latest version.'
			}));
		}	
	}

	useEffect(() => {
		getFile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeUser, timestamp]);

	return { isLoadingFileDetails, file, fileErrorToken };
}
