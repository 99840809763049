import { useState } from "react";
import { userImportTemplateConstants } from "../../../constants/userImportTemplateConstants";
import { PostUserDTO } from "../../types/PostUserDTO";
import useApi from "../useApi";

const useUserDownload = () : {
    isRequestInProgress: boolean;
	pendingUsersFromApiCount?: number;
	downloadUsers: () => void;
	resetState: () => void;
} => {
    const api = useApi();
	const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false);
	const [pendingUsersFromApiCount, setPendingUsersFromApiCount] = useState<number>(null);

	// Method to reset necessary state values after initial run
	const resetState = () => {
		setPendingUsersFromApiCount(null);
	}

	// Method exposed on the hook that can be called in a component to get the users
    const downloadUsers = () => {
		Excel.run(async (context) => {
			setIsRequestInProgress(true);

			// Get the currently active worksheet
			const sheet = context.workbook.worksheets.getActiveWorksheet();

			const usersTable = sheet.tables.getItemOrNullObject(userImportTemplateConstants.tableName);
            const bodyRange = usersTable.getDataBodyRange().load("values, rowCount");
			const tableRows = usersTable.rows.load("items");

			await context.sync();

			// Refreshing the users, clear the result column
			const resultColumn = usersTable.columns.getItemOrNullObject(userImportTemplateConstants.resultColumnHeader);

			if (resultColumn) {
				resultColumn.delete();
			}
			
			// Get all the pending company users
			const response = await api.get("users?limit=0&filter=isProfileComplete eq false and userType eq 1");

			// If the call to get the users succeeded
			if (response.ok) {
				const existingUsersInSpreadsheet = getUsersInSpreadsheet(bodyRange.values);
				const pendingUsersFromApi = await response.json();
				
				setPendingUsersFromApiCount(pendingUsersFromApi.length);

				if (pendingUsersFromApi.length === 0) {
					setIsRequestInProgress(false);
					return;
				}

				// Delete each row in the table
				for (let i = tableRows.items.length - 1; i >= 0; i--) {
					tableRows.items[i].delete();
				}

				await context.sync();

				// Output the the users that already exist in the spreadsheet merged with those retrieved from the API
				usersTable.rows.add(0, mergeUsersInSpreadsheetWithUsersFromApi(existingUsersInSpreadsheet, pendingUsersFromApi).map(user => [
					user.userName, 
					user.firstName, 
					user.lastName, 
					user.workPhone, 
					user.mobilePhone, 
					user.referenceNumber, 
					user.email, 
					user.tier2Name, 
					user.userRole
				]));

				usersTable.getDataBodyRange().format.autofitColumns();
			} else {
				const responseBody = await response.json();
				const errorMessage = `Error: ${responseBody.message}`;
				console.error(errorMessage);
			}

			setIsRequestInProgress(false);
		}).catch(excelError => {
			console.error(excelError);
			setIsRequestInProgress(false);
		});
	};

	// Get the users that are in the spreadsheet
	const getUsersInSpreadsheet = (cellValues): PostUserDTO[] => {
		const users = [];

		// Process each row 
		for (let i = 0; i < cellValues.length; i++) {
			const row = cellValues[i];

			// Check if the row is empty and skip them
			if (row.every((cell) => cell === null || cell === '')) {
				continue;
			}

			// Create a user object for the current row
			const user : PostUserDTO = {
				userName: row[0],
				firstName: row[1],
				lastName: row[2],
				workPhone: row[3],
				mobilePhone: row[4],
				referenceNumber: row[5],
				email: row[6],
				tier2Name: row[7],
				userRole: row[8],
				includedInRelatedProjects: true
			};

			users.push(user);
		}

		return users;
	};

	// Combine the users in the spreadsheet with the users retrieved from the API
	const mergeUsersInSpreadsheetWithUsersFromApi = (existingUsersInSpreadsheet, pendingUsersFromApi) : PostUserDTO[] => {
		return existingUsersInSpreadsheet.concat(
			pendingUsersFromApi.map((userFromApi) => {
				// Check for each of the users retrieved from the API to see if are they already in the spreadsheet
				const matchingUser = existingUsersInSpreadsheet.find(
					(userInSpreadsheet) => userInSpreadsheet.userName === userFromApi.username
				);
				
				// If they are already in the spreadsheet, return that data
				if (matchingUser) {
					return {
						...matchingUser
					};
				} 

				// Otherwise, return a new record with the username set
				return {
					userName: userFromApi.username,
					firstName: null,
					lastName: null,
					workPhone: null,
					mobilePhone: null,
					referenceNumber: null,
					email: null,
					tier2Name: null,
					userRole: null,
					includedInRelatedProjects: null,
				};
			})
		).reduce((acc: PostUserDTO[], curr: PostUserDTO) => {
			// Check if the current user already exists in the accumulator array
			const matchingIndex = acc.findIndex((user) => user.userName === curr.userName);
			
			// If the user exists, merge the properties of the existing and current user
			if (matchingIndex !== -1) {
				acc[matchingIndex] = {
					...acc[matchingIndex],
					...curr,
				};
			} else {
				// Otherwise, add the current user to the accumulator array
				acc.push(curr);
			}
			return acc;
		}, []);
	};

    return { 
		isRequestInProgress, 
		pendingUsersFromApiCount, 
		downloadUsers,
		resetState
	}
}

export default useUserDownload;