import React, { useState, useEffect } from 'react';
import { MessageBarType, ProgressIndicator } from '@fluentui/react';
import { defineMessage, useIntl } from 'react-intl';
import { i18nMessage } from '../types/i18nMessage';
import Message from './Message';

export interface ILoadingProps {
	label?: i18nMessage;
	displayExcelCellEditWarning?: boolean;
	progress?: number;
}

const Loading: React.FunctionComponent<ILoadingProps> = ({ label, displayExcelCellEditWarning, progress }) => {
	const intl = useIntl();
	const [renderExcelWarning, setRenderExcelWarning] = useState(false);

	if (!label) {
		label = defineMessage({ id: 'loading', defaultMessage: 'Loading' });
	}

	// Only want to show the warning after 5 seconds to prevent a flicker
	// and to give it a chance to load before presenting the message
	useEffect(() => {
		let timeoutId;
		if (displayExcelCellEditWarning) {
			timeoutId = setTimeout(() => {
				setRenderExcelWarning(true);
			}, 5000);
		}

		return () => {
			// Cleanup function to cancel any pending setTimeout
			clearTimeout(timeoutId);
		};
	}, [displayExcelCellEditWarning]);

	return (
		<>
			<ProgressIndicator label={intl.formatMessage(label)} percentComplete={progress} />
			{renderExcelWarning && (
				<Message
					message={defineMessage({
						id: 'ensureYouAreNotInCellEditMode',
						defaultMessage: 'Please ensure you are not in cell-editing mode.'
					})}
					messageBarType={MessageBarType.info}
				/>
			)}
		</>
	);
};

export default Loading;
