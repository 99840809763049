import { useEffect, useState, useContext } from 'react';
import useApi from './useApi';
import { FilePostDTO } from '../types/FilePostDTO';
import useOfficeFile from './useOfficeFile';
import { CustomPropertiesContext } from '../../context/CustomPropertiesContext';
import { CRMFile } from '../types/CRMFile';
import { Utils } from '../../utils/Utils';
import { CustomDocumentProperties } from '../../enums/CustomDocumentProperties';
import { determineErrorToken } from '../helpers/ErrorHelper';
import { endpointRefs } from '../../constants/errorTokens';
import { i18nMessage } from '../types/i18nMessage';
import { defineMessage } from 'react-intl';

// Custom hook to assist with the handling and uploading of files
const useFileUpload = () => {
    const { fileId } = useContext(CustomPropertiesContext);
    const [errorToken, setErrorToken] = useState<i18nMessage>();
    const [successToken, setSuccessToken] = useState<i18nMessage>();
    const [isFileUploadRequestInProgress, setIsFileUploadRequestInProgress] = useState(false);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
    const [isUploadRequestSafeToSend, setIsUploadRequestSafeToSend] = useState(false);
    const [isReservationToggleSuccessful, setIsReservationToggleSuccessful] = useState(false);
    const [isReservationRequestInProgress, setIsReservationRequestInProgress] = useState(false);
    const [isTimestampRefreshInProgress, setIsTimestampRefreshInProgress] = useState(false);
    const api = useApi();
    const officeFile = useOfficeFile();

    const isRequestInProgress = isFileUploadRequestInProgress || isReservationRequestInProgress || isTimestampRefreshInProgress;

    const uploadFile = async (filePostDto: FilePostDTO, endpoint: string, keepReservation: boolean, successToken: i18nMessage) => {
        setSuccessToken(successToken);
        await processUpload(filePostDto, endpoint);
        await toggleReservation(keepReservation);
    };
    
    // Toggle the reservation status of the file
    const toggleReservation = async (reserveFile: boolean) => {
        setIsReservationRequestInProgress(true);
        let toggleReservationResponse: Response;
        
        try {
            const newTimestamp = await refreshTimestampCustomProperty();
            toggleReservationResponse = await api.patch('files?reserveFile=' + reserveFile, { fileProConId: fileId, version: newTimestamp });
            await refreshTimestampCustomProperty();
        } catch (error) {
            setErrorToken(determineErrorToken(endpointRefs.releaseReservation, toggleReservationResponse.status));
            console.error('Error:', error);
            throw error;
        }

        setIsReservationRequestInProgress(false);
        setIsReservationToggleSuccessful(true);
    }

    const processUpload = async (filePostDto: FilePostDTO, endpoint: string) => {
        await checkUploadIsSafeToSend();

        if (!isUploadRequestSafeToSend) {
            return;
        }

        setIsFileUploadRequestInProgress(true);

        const currentOpenFile: Office.File = await officeFile.getOfficeFile();

        const postFileResponse = await postFile(currentOpenFile.size, endpoint, filePostDto);

        if (postFileResponse.ok) {
            const fileBytes = new Uint8Array(currentOpenFile.size);

			for (let sliceIndex = 0; sliceIndex < currentOpenFile.sliceCount; sliceIndex++) {
				const slice = await officeFile.getSlice(currentOpenFile, sliceIndex);
				fileBytes.set(slice.data, sliceIndex);
			}

			const blob: Blob = new Blob([fileBytes]);

			// Upload the contents of our file to the specified location on the server
			const patchContentsResponse = await patchContents(
				new URL(postFileResponse.headers.get('location')).pathname.toLowerCase().replace('/proconrestapi/procon/v1/', ''),
				blob
			);

            if (patchContentsResponse.ok) {
                setIsUploadSuccessful(true);
            }
        }

        setIsFileUploadRequestInProgress(false);
        officeFile.closeFile(currentOpenFile);
    };

    const postFile = async (fileSize: number, endpoint: string, requestBody: object) : Promise<Response> => {
        let postFileResponse: Response;

        try {
            const customHeaders: HeadersInit = {
                'Upload-length': fileSize.toString()
            }
            
            postFileResponse = await api.post(endpoint, requestBody, customHeaders);
            return postFileResponse;
        } catch (error) {
            console.error('Error:', error);
            setErrorToken(determineErrorToken(endpointRefs.postFile, postFileResponse.status));
            throw error;
        }
    };

    const patchContents = async (endpoint: string, blob: Blob) : Promise<Response> => {
        let patchContentsResponse: Response;

        try {
            patchContentsResponse = await api.patchContents(endpoint, blob);
            return patchContentsResponse;
        } catch (error) {
            console.error('Error:', error);
            setErrorToken(determineErrorToken(endpointRefs.patchFileContents, patchContentsResponse.status));
            throw error;
        }
    };

    const refreshTimestampCustomProperty = async () => {
        let fileData: CRMFile

        setIsTimestampRefreshInProgress(true);

		try {
			const fileGetResponse = await api.get('files/' + fileId);

			if (fileGetResponse.ok) {
				fileData = await fileGetResponse.json();
			} else {
				setErrorToken(determineErrorToken(endpointRefs.getFile, fileGetResponse.status));
			}
		} catch (error) {
			setErrorToken(determineErrorToken(endpointRefs.genericErrors, 501));
		}
        
        Utils.updateCustomProperty(CustomDocumentProperties.Timestamp, fileData.version);
        setIsTimestampRefreshInProgress(false);
        return fileData.version;
	}

    const checkUploadIsSafeToSend = async () => {
		if (Office.context.host === Office.HostType.Word) {
			setIsUploadRequestSafeToSend(true);
            return;
		}

		await Excel.run(async (context) => {
			try {
				// There is no method to check Excel is in cell edit mode on the office-js API, so this is a way to test it by
				// trying to get the custom properties - which is not possible when in edit mode and an exception is thrown
				context.workbook.properties.custom.getCount();
				await context.sync();
				setIsUploadRequestSafeToSend(true);
			} catch {
                setErrorToken(defineMessage({
                    id: 'cannotUploadWhileInEditMode',
                    defaultMessage: 'The file cannot be uploaded. Please ensure you are not in cell-editing mode.'
                }));
				setIsUploadRequestSafeToSend(false);
			}
		});
	}

    useEffect(() => {
        checkUploadIsSafeToSend();
    }, []);

    return { 
        uploadFile,
        toggleReservation,
        errorToken,
        successToken, 
        isUploadRequestSafeToSend, 
        isUploadSuccessful,
        isReservationToggleSuccessful,
        isRequestInProgress
    };
};

export default useFileUpload;