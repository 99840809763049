import { ActionButton, IStackTokens, MessageBarType, Stack } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from '../Loading';
import Message from '../Message';
import { defineMessage } from '@formatjs/intl';
import useFileUpload from '../../hooks/useFileUpload';

type props = {
	setPage: (page: string) => void;
};
const stackTokens: IStackTokens = {
	childrenGap: 0,
	padding: 'm'
};

const ReleaseReservation: React.FunctionComponent<props> = ({ setPage }: props) => {
	const fileUpload = useFileUpload();

	return (
		<>
			<h2 className='ms-fontWeight-light'>
				<FormattedMessage id='clearReservation' defaultMessage='Clear Reservation' />
			</h2>

			{fileUpload.isRequestInProgress && 
				<Loading />
			}
			
			{fileUpload.errorToken && 
				<Message messageBarType={MessageBarType.error} message={fileUpload.errorToken} />
			}

			{fileUpload.isReservationToggleSuccessful && (
				<Message
					messageBarType={MessageBarType.success}
					message={defineMessage({
						id: 'reservationCleared',
						defaultMessage: 'The file is no longer reserved for editing.'
					})}
				/>
			)}

			{!fileUpload.isRequestInProgress && !fileUpload.isReservationToggleSuccessful &&
				<>	
					<Stack grow verticalFill tokens={stackTokens}>
						<Stack.Item>
							<ActionButton
								className='ms-welcome__action'
								iconProps={{ iconName: 'Unlock' }}
								onClick={() => fileUpload.toggleReservation(false)}
							>
								<FormattedMessage id='confirm' defaultMessage='Confirm' />
							</ActionButton>
						</Stack.Item>
						<Stack.Item>
							<ActionButton
								className='ms-welcome__action'
								iconProps={{ iconName: 'Cancel' }}
								onClick={() => {
									setPage('HOME');
								}}
							>
								<FormattedMessage id='cancel' defaultMessage='Cancel' />
							</ActionButton>
						</Stack.Item>
					</Stack>
				</>}
		</>
	);
};

export default ReleaseReservation;
