const useOfficeFile = () => {
    // Retrieve file contents using the Office API
	const getOfficeFile = (): Promise<Office.File> => {
		return new Promise((resolve, reject) => {
			Office.context.document.getFileAsync(Office.FileType.Compressed, function (result) {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve(result.value);
				} else {
					reject(result.error);
					console.error(result.error);
				}
			});
		});
	}

    // Get the next slice of the file contents
	const getSlice = (file: Office.File, sliceIndex: number): Promise<Office.Slice> => {
		return new Promise((resolve, reject) => {
			file.getSliceAsync(sliceIndex, (result) => {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve(result.value);
				} else {
					reject(result.error);
					console.error(result.error);
				}
			});
		});
	}

	// Close async stream of the file contents
	const closeFile = (file: Office.File): Promise<void> => {
		return new Promise((resolve, reject) => {
			file.closeAsync((result) => {
				if (result.status == Office.AsyncResultStatus.Succeeded) {
					resolve();
				} else {
					reject(result.error);
					console.log(result.error);
				}
			});
		});
	}

    return { getOfficeFile, getSlice, closeFile };
};

export default useOfficeFile;
