/* eslint-disable @typescript-eslint/no-explicit-any */
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { OfficeCanvas } from './components/Office';

initializeIcons();

const render = (Component) => {
	ReactDOM.render(
		<AppContainer>
			<OfficeCanvas>
				<Component />
			</OfficeCanvas>
		</AppContainer>,
		document.getElementById('container')
	);
};

/* Render application after Office initializes */
Office.initialize = () => {
	render(App);
};

if ((module as any).hot) {
	(module as any).hot.accept('./components/App', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const NextApp = require('./components/App').default;
		render(NextApp);
	});
}
